import { config } from 'src/config'
import { SCHEMA_PRODUCT_CARD } from 'src/selectors'

import type { Maybe } from 'src/data/graphql-generated'
import type { ProductReview } from 'src/types/Product'

interface ProductSchemaProps {
  name?: Maybe<string>
  image?: Maybe<string>
  description?: Maybe<string>
  path: string
  brand: string | null
  priceNumeric: number
  ratingValue: number
  ratingCount: number
  gtin13?: Maybe<string>
  mpn: string | null
  isItemAvailable: boolean
  reviews?: ProductReview[]
  minDeliveryTime: number
  maxDeliveryTime: number
}

export const ProductSchema = ({
  name,
  image,
  description,
  path,
  brand,
  priceNumeric,
  ratingValue,
  ratingCount,
  gtin13,
  mpn,
  isItemAvailable,
  reviews = [],
  minDeliveryTime,
  maxDeliveryTime,
}: ProductSchemaProps) => (
  <script
    type="application/ld+json"
    data-cy={SCHEMA_PRODUCT_CARD}
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'Product',
        'name': name,
        'url': `https://www.coffeecircle.com${path}`,
        'image': image,
        'description': description,
        'gtin13': gtin13,
        'mpn': `${mpn}`,
        'brand': { '@type': 'Brand', 'name': brand },
        'offers': {
          '@type': 'Offer',
          'priceCurrency': 'EUR',
          'price': priceNumeric,
          'itemCondition': 'http://schema.org/NewCondition',
          'availability': `http://schema.org/${isItemAvailable ? 'InStock' : 'OutOfStock'}`,
          'shippingDetails': {
            '@type': 'OfferShippingDetails',
            'shippingRate': {
              '@type': 'MonetaryAmount',
              'value': priceNumeric < config.shippingFreeFrom ? config.shippingFee : 0,
              'currency': 'EUR',
            },
            'deliveryTime': {
              '@type': 'ShippingDeliveryTime',
              'handlingTime': {
                '@type': 'QuantitativeValue',
                'minValue': minDeliveryTime - 1 > -1 ? minDeliveryTime - 1 : 0,
                'maxValue': maxDeliveryTime - 3 > -1 ? maxDeliveryTime - 3 : 0,
                'unitCode': 'DAY',
              },
              'transitTime': {
                '@type': 'QuantitativeValue',
                'minValue': 1,
                'maxValue': 3,
                'unitCode': 'DAY',
              },
            },
            'shippingDestination': {
              '@type': 'DefinedRegion',
              'addressCountry': config.shippingCountries.map(({ code }) => code),
            },
          },
          'hasMerchantReturnPolicy': {
            '@type': 'MerchantReturnPolicy',
            'returnPolicyCategory': 'https://schema.org/MerchantReturnFiniteReturnWindow',
            'merchantReturnDays': config.returnRefundTime,
            'returnMethod': 'https://schema.org/ReturnByMail',
            'returnFees': 'https://schema.org/FreeReturn',
            'applicableCountry': config.shippingCountries.map(({ code }) => code),
          },
        },
        ...(ratingCount > 0 && {
          aggregateRating: {
            '@type': 'AggregateRating',
            'bestRating': 5,
            'worstRating': 1,
            'ratingValue': ratingValue,
            'reviewCount': ratingCount,
          },
        }),
        ...(reviews.length > 0 && {
          review: reviews.map((review) => ({
            '@type': 'Review',
            'author': {
              '@type': 'Person',
              'name': review.customer.name || 'Kunde',
            },
            'datePublished': review.submittedAt,
            'description': review.comment || review.title,
            'reviewRating': {
              '@type': 'Rating',
              'ratingValue': review.rating,
              'bestRating': 5,
              'worstRating': 1,
            },
          })),
        }),
      }),
    }}
    suppressHydrationWarning
  />
)
