import { Carousel as MantineCarousel } from '@mantine/carousel'
import { useMediaQuery } from '@mantine/hooks'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { Children } from 'react'
import { useTranslation } from 'react-i18next'

import '@mantine/carousel/styles.css'
import styles from './Carousel.module.scss'

import type { CarouselProps as MantineCarouselProps } from '@mantine/carousel'
import type { ReactElement } from 'react'

interface CarouselProps extends MantineCarouselProps {
  variant: 'products' | 'cards'
  mobileBreakpoint?: number
  mobileInitialSlide?: number
}

export const Carousel = ({
  variant,
  mobileBreakpoint = 9000,
  mobileInitialSlide = 0,
  children,
  ...props
}: CarouselProps) => {
  const slides = Children.toArray(children) as ReactElement[]

  const isMobile = useMediaQuery(`(max-width: ${mobileBreakpoint}px)`)
  const { t } = useTranslation()

  const productsProps: MantineCarouselProps = {
    align: 'start',
    classNames: {
      controls: styles.controls,
      control: styles.control,
    },
  }
  const cardsProps: MantineCarouselProps = {
    withControls: false,
    slidesToScroll: isMobile ? 1 : 4,
    withIndicators: isMobile,
  }

  return (
    <MantineCarousel
      height="100%"
      slidesToScroll="auto"
      slideSize={{ sm: '85%', md: '50%', lg: '33.333333%' }}
      slideGap="xs"
      initialSlide={isMobile ? mobileInitialSlide : 0}
      loop={false}
      nextControlIcon={<IconChevronRight size={20} />}
      nextControlProps={{ 'aria-label': t('common.next') }}
      previousControlIcon={<IconChevronLeft size={20} />}
      previousControlProps={{ 'aria-label': t('common.previous') }}
      {...(variant === 'products' && productsProps)}
      {...(variant === 'cards' && cardsProps)}
      {...props}
      classNames={{
        root: styles.root,
        indicator: styles.indicator,
        ...(variant === 'products' && productsProps.classNames),
        ...props.classNames,
      }}
    >
      {slides.map((slide) => (
        <MantineCarousel.Slide key={slide.key}>{slide}</MantineCarousel.Slide>
      ))}
    </MantineCarousel>
  )
}
