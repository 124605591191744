import type { Product, ProductVariant } from 'src/data/graphql-generated'

export const findProductAndVariantBySku = (
  products: Product[],
  sku: string,
): { product: Product | undefined; variant: ProductVariant | undefined } => {
  let variant: ProductVariant | undefined
  const product = products.find((p) => {
    variant = p?.variants.nodes.find((v) => v.sku === sku)
    return !!variant
  })

  if (!product) return { product: undefined, variant: undefined }

  return { product, variant }
}
