import { Carousel } from 'src/components/Carousel/Carousel'
import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { ProductCard } from 'src/components/ProductCard/ProductCard'
import { Text } from 'src/components/Text/Text'
import { findProductAndVariantBySku } from 'src/functions/product/findProductAndVariantBySku'

import styles from './ProductsCarousel.module.scss'

import type { EnrichedProductCarousel } from 'src/types/HomepageContent'

export const ProductsCarousel = ({ sectionTitle, sectionSubtitle, products, skus }: EnrichedProductCarousel) => (
  <Container>
    <section className={styles.textContainer}>
      <Headline variant="h2">{sectionTitle}</Headline>
      <Text>{sectionSubtitle}</Text>
    </section>

    <Carousel variant="products" slideSize={{ base: '85%', md: '20%' }}>
      {skus.map((sku) => {
        const { product, variant } = findProductAndVariantBySku(products, sku)

        if (!product) return null
        return <ProductCard key={sku} product={product} variant={variant} isInsideCarousel />
      })}
    </Carousel>
  </Container>
)
